<template>
  <div class="transaction-menu-header">
    <div class="ri-bold-body margin-bottom-sm">{{ categoryDisplayName }}
      <span v-if="shouldShowSubcategory" class="ri-body"> · {{ transaction.expense }}</span>
    </div>
    <div class="ri-large-display margin-bottom-s">
      {{ formattedAmount }}<span class="ri-medium-bold-headline"> ₪</span>
    </div>
    <div class="transaction-details ri-body">
      <div v-if="isDifferentOriginalAmount">(סכום מקורי: {{ formattedOriginalAmount | shekel }})</div>
      <div>{{ businessName }}</div>
      <div v-if="actualTransaction">{{ account }}</div>
      <div v-if="actualTransaction">{{ date }}</div>
      <div v-if="predictedWithNoActual" class="ri-bold-body">{{ predictionText }}</div>
    </div>
  </div>
</template>

<script>

import moneyUtils from '@/utils/money';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import TransactionDisplayUtils from '../../TransactionDisplayUtils';
import cashflowViewConsts from '../../../../../../../../../constants/cashflow-view';

const { categoryNameToTransactionCategory, CATEGORY_NAMES } = cashflowViewConsts;
export default {
  name: 'TransactionMenuHeader',
  mixins: [TransactionDisplayUtils],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    isPredictedTransaction: {
      type: Boolean,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('featureFlags', ['showTransactionOriginalAmount']),
    predictedWithNoActual() {
      return this.isPredictedTransaction && !this.transaction.actual;
    },
    actualTransaction() {
      return this.transaction.actual ?? this.transaction;
    },
    account() {
      if (this.predictedWithNoActual) {
        return null;
      }
      return this.getTransactionAccount(this.actualTransaction);
    },
    businessName() {
      return this.predictedWithNoActual ? this.transaction.name : this.actualTransaction.businessName;
    },
    date() {
      if (this.predictedWithNoActual) {
        return null;
      }
      return this.formatDate(this.actualTransaction.transactionDate);
    },
    amount() {
      return this.predictedWithNoActual
        ? this.transaction.predictedAmount
        : this.getTransactionAmountWithoutFraction(this.actualTransaction);
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.amount, 1);
    },
    categoryDisplayName() {
      return categoryNameToTransactionCategory[this.categoryName]
        ? categoryNameToTransactionCategory[this.categoryName]
        : cashflowNormalizer.TC_DISPLAY_NAME[this.transaction.trackingCategory.name] || this.transaction.trackingCategory.name;
    },
    predictionText() {
      return this.transaction.isIncome ? 'הכנסה צפויה' : 'הוצאה צפויה';
    },
    shouldShowSubcategory() {
      return [CATEGORY_NAMES.FIXED_EXPENSE, CATEGORY_NAMES.FIXED_INCOME].includes(this.categoryName);
    },
    isDifferentOriginalAmount() {
      if (!this.showTransactionOriginalAmount) {
        return false;
      }
      return !_.isNil(this.actualTransaction.originalAmount) && this.actualTransaction.originalAmount !== this.amount;
    },
    formattedOriginalAmount() {
      return moneyUtils.formatAmountWithFraction(this.actualTransaction.originalAmount, 1);
    },
  },
  methods: {

  },
};

</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/spacings";

.transaction-menu-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: right;

  .transaction-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: right;
  }
}
</style>
