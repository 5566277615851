<template>
  <on-transaction-label :on-click="this.openUpdateCCModal" variant="warning">
    <template v-slot:label-text>
      <div>למערכת חסר פירוט של חיוב האשראי</div>
    </template>
  </on-transaction-label>
</template>
<script>

import Segment from '@/Segment';
import PapaMergeQuestionsPopupsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/PapaMergeQuestionsPopupsMixin.vue';
import OnTransactionLabel
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/OnTransactionLabel.vue';

export default {
  name: 'papa-investigation-label',
  components: { OnTransactionLabel },
  props: {
    papaInvestigation: { required: true },
  },
  mixins: [PapaMergeQuestionsPopupsMixin],
  methods: {
    openUpdateCCModal() {
      Segment.trackUserInteraction('PapaInvestigationLabelClicked',
        { transactionId: this.papaInvestigation.transactionId, sourceName: this.papaInvestigation.cardSource });

      this.openInvestigationPopup(this.papaInvestigation);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
