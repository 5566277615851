<template>
  <on-transaction-label :on-click="this.openUpdateCCModal" variant="warning">
    <template v-slot:label-text>
      <div>{{title}}</div>
    </template>
  </on-transaction-label>
</template>
<script>

import { mapActions } from 'vuex';
import Segment from '@/Segment';
import OnTransactionLabel
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/OnTransactionLabel.vue';
import dateUtils from '@/utils/dates';
import HighIncomeExclusionPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/HighIncomeExclusionPopup.vue';

export default {
  name: 'high-income-exclusion-label',
  components: { OnTransactionLabel },
  props: {
    transaction: { required: true },
  },
  computed: {
    monthName() {
      return dateUtils.getHebrewMonth(this.transaction.transactionDate);
    },
    title() {
      return `ההכנסה הזאת גבוהה במיוחד ולכן המערכת הוציאה אותה מהתזרים של ${this.monthName}`;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openUpdateCCModal() {
      Segment.trackUserInteraction('HighIncomeExclusionLabelClicked');
      this.openModal(
        {
          component: HighIncomeExclusionPopup,
          props: {
            transaction: this.transaction,
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
