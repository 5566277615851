<template>
  <div class="transaction-container" tabindex="-1">
    <div role="button"
         tabindex="0"
         @click="isTransactionEditable && selectTransaction()"
         @keyup.enter.stop="isTransactionEditable && selectTransaction()">
      <transaction-row :out-of-date=outOfDate :transaction="transaction" :split-from-transaction="splitFromTransaction"
                       :is-editable="isTransactionEditable" :category-id="categoryId"
                       :papa-investigation="papaInvestigation" :has-update="hasUpdate"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as _ from 'lodash';
import Segment from '@/Segment';
import cashflowViewConsts from '@/constants/cashflow-view';
import EventBus from '@/event-bus/event-bus';
import * as HamsterApi from '@/api/HamsterApi';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils.vue';
import TransactionMenu from './TransactionMenu';
import transactionUtils from '../../../../../../../../../utils/transaction';
import TransactionRow from './TransactionRow.vue';

// eslint-disable-next-line max-len
const MoveTransactionToSaving = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MoveTransactionToSaving');
// eslint-disable-next-line max-len
const MoveOneTimeSavingTransactionToFixedSaving = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MoveOneTimeSavingTransactionToFixedSaving');
// eslint-disable-next-line max-len
const MoveOneTimeSavingToVariable = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MoveOneTimeSavingToVariable');
// eslint-disable-next-line max-len
const ChangeTransactionCategoryPopup = () => import('../../../current-month/components/cashflow-edit/edit-transaction/ChangeTransactionCategoryPopup.vue');
// eslint-disable-next-line max-len
const SplitTransactionPopup = () => import('../../../current-month/components/cashflow-edit/edit-transaction/split-transaction/SplitTransactionPopup.vue');
const AddTransactionComment = () => import('../../../current-month/components/cashflow-edit/edit-transaction/AddTransactionComment.vue');
const Toaster = () => import('@/base-components/Toaster');

export default {
  name: 'Transaction',
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    canEditCategory: {
      type: Boolean,
      required: true,
    },
    hasUpdate: {
      type: Boolean,
      default: false,
    },
    categoryName: {
      type: String,
      required: false,
    },
    categoryId: {
      type: String,
      default: '',
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'not-undefined',
    },
  },
  mixins: [TransactionDisplayUtils],
  components: {
    TransactionRow,
  },
  computed: {
    ...mapGetters('cashflowView', ['transactionsInCurrentCashflow']),
    ...mapGetters('mergeInvestigationQuestions', ['classifiedMergeInvestigation']),
    splitFromTransaction() {
      return this.transaction.splitFrom && _.find(this.transactionsInCurrentCashflow, { transactionId: this.transaction.splitFrom });
    },
    incomeOrExpense() {
      return transactionUtils.incomeOrExpense(this.transaction);
    },
    isExcludedCategory() {
      return this.categoryName === cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_INCOME
          || this.categoryName === cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_EXPENSE;
    },
    isSaving() {
      return this.transaction.isSaving;
    },
    isExcludedSaving() {
      return this.transaction.isExcludedSaving;
    },
    isTransactionEditable() {
      return this.canEditCategory && !this.papaInvestigation;
    },
    papaInvestigation() {
      return this.classifiedMergeInvestigation(this.transaction.transactionId);
    },
  },
  methods: {
    ...mapActions('editTransaction', ['startEditingTransaction']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('editCashflow', ['setComment']),
    selectTransaction() {
      this.$router.push({ query: { ...this.$route.query, transactionId: this.transaction.transactionId }, params: this.$route.params });
    },
    getShareActionMessageText() {
      // Building the router state for a transaction, so we can have the link to the current state
      // but with the transaction id in the query params. Adding the origin, since the router doesn't
      // have the full URL, only the path.
      const transactionRoutePath = this.$router.resolve({
        name: this.$route.name,
        params: this.$route.params,
        query: { ...this.$route.query, transactionId: this.transaction.transactionId },
      });
      const transactionLink = `${window.location.origin}${transactionRoutePath.href}`;
      const transactionAmount = +this.getTransactionAmountWithoutFraction(this.transaction);
      const { businessName } = this.transaction;
      const actionText = this.transaction.isIncome ? 'שנכנסו' : 'שהוצאנו';
      const connectionLetter = this.transaction.isIncome ? 'מ' : 'ב';

      return `היי, ראיתי ${actionText} ${transactionAmount} ש״ח ${connectionLetter}${businessName}.%0A${encodeURIComponent(transactionLink)}`;
    },
    getShareActionPerMember() {
      /*
      Each time a component that uses computed properties (via mapGetters) is created,
      Vue sets up watchers on the component’s computed properties.
      This is not particularly expensive for a single component,
      but when a customer has many transactions it adds up and creates a performance bottleneck.
       */
      if (this.$store.getters['session/numberOfMembers'] === 1 || !this.$store.getters['featureFlags/enableShareTransaction']) {
        return [];
      }
      return this.$store.getters['session/otherMembers'].map(member => {
        return {
          icon: 'share-with-member',
          onClick: () => {
            const WALinkWithPhoneNumber = `https://wa.me/${member.phone}?text=${this.getShareActionMessageText()}`;
            Segment.trackUserInteraction('ShareTransactionClicked');
            window.open(WALinkWithPhoneNumber, '_blank');
          },
          text: `להתייעץ עם ${member.name} על ה${this.incomeOrExpense}`,
          showNewBadge: true,
        };
      });
    },
    splitText() {
      if (this.transaction.splitFrom) {
        return 'לערוך את הפיצול';
      }
      return this.transaction.incomeAmount ? 'לפצל את ההכנסה' : 'לפצל את ההוצאה';
    },
    getActions() {
      const actionsMapping = {
        editCategory: {
          text: `להזיז את ה${this.incomeOrExpense}`,
          icon: 'edits-move',
          onClick: () => this.openEditCategory(ChangeTransactionCategoryPopup),
        },
        editIncomeCategory: {
          text: `זו לא ${this.incomeOrExpense} משתנה`,
          icon: 'edits-move',
          onClick: () => this.openEditCategory(ChangeTransactionCategoryPopup),
        },
        moveExcludedBack: {
          text: 'להחזיר לתזרים של החודש',
          icon: 'edits-exclude',
          onClick: () => this.openEditCategory(ChangeTransactionCategoryPopup),
        },
        addComment: {
          text: this.transaction.customerComment ? 'לערוך את ההערה' : 'להוסיף הערה',
          icon: 'comment',
          onClick: () => this.openEditCategory(AddTransactionComment),
        },
        splitTransaction: {
          text: this.splitText(),
          icon: 'split',
          onClick: () => this.openEditCategory(SplitTransactionPopup),
        },
        moveToSaving: {
          icon: 'edits-is-savings',
          onClick: this.openEditTransaction(MoveTransactionToSaving),
          text: 'זו הפקדה לחיסכון!',
        },
        moveToFixedSaving: {
          icon: 'savings-not-fixed',
          onClick: this.openEditTransaction(MoveOneTimeSavingTransactionToFixedSaving),
          text: 'זו הפקדה קבועה לחיסכון',
        },
        notOneTimeSaving: {
          icon: 'edits-not-savings',
          onClick: this.openEditTransaction(MoveOneTimeSavingToVariable),
          text: 'זו לא הפקדה לחיסכון',
        },
        markOrUnmarkExcludedAsSavings: {
          icon: this.isExcludedSaving ? 'edits-not-savings' : 'edits-is-savings',
          onClick: this.markOrUnmarkExcludedAsSavings,
          text: this.isExcludedSaving ? 'זו לא הפקדה לחיסכון' : 'זו הפקדה לחיסכון לא מהתזרים',
        },
        shareWithMemberActions: this.getShareActionPerMember(),
      };
      return getActions(this.isExcludedCategory, this.isSaving, this.categoryName, this.transaction.isIncome,
        actionsMapping, !!this.transaction.splitFrom);
    },
    toggleActions() {
      this.openModal({
        component: TransactionMenu,
        props: {
          actions: this.getActions(),
          categoryName: this.categoryName,
          transaction: this.transaction,
          isPredictedTransaction: false,
          isTemp: this.transaction.isTemp,
        },
        popupAlignment: 'menu',
        eventHandlers: {
          close: this.removeTransactionFromUrl,
        },
      });
    },
    removeTransactionFromUrl() {
      if (this.$route.query.transactionId) {
        this.$router.push({ query: { ...this.$route.query, transactionId: undefined }, params: this.$route.params });
      }
    },
    openEditCategory(component) {
      Segment.trackUserInteraction('EditTransactionClicked', {
        businessName: this.transaction.businessName,
        transactionId: this.transaction.transactionId,
      });
      this.startEditingTransaction({
        transaction: this.transaction,
        categoryName: this.categoryName,
        categoryId: this.categoryId,
      });
      this.openModal({
        component,
      });
    },
    openEditTransaction(popupToOpen) {
      return () => {
        Segment.trackUserInteraction('EditTransactionClicked', {
          businessName: this.transaction.name,
          transactionId: this.transaction.transactionId,
        });
        this.openModal({
          component: popupToOpen,
          props: {
            transaction: this.transaction,
            categoryId: this.categoryId,
            categoryName: this.categoryName,
          },
        });
      };
    },
    async markOrUnmarkExcludedAsSavings() {
      const commentPrefix = 'חיסכון 💰';
      const cleanedCustomerComment = _.replace(this.transaction.customerComment, commentPrefix, '');

      if (!this.isExcludedSaving) {
        const updatedSavingTransactionIds = (await HamsterApi.markAsSavingTransaction(this.transaction.transactionId))
          .map(t => t.transactionId);
        cashflowNormalizer.setSavingTransactions(updatedSavingTransactionIds);
        const savingComment = commentPrefix + cleanedCustomerComment;
        this.setComment({ transactionId: this.transaction.transactionId, comment: savingComment });
        EventBus.$emit('open-toaster', {
          component: Toaster,
          props: { text: 'ההוצאה סומנה כחיסכון לא תזרימי' },
        });
      } else {
        const updatedSavingTransactionIds = (await HamsterApi.unmarkAsSavingTransaction(this.transaction.transactionId))
          .map(t => t.transactionId);
        cashflowNormalizer.setSavingTransactions(updatedSavingTransactionIds);
        this.setComment({ transactionId: this.transaction.transactionId, comment: cleanedCustomerComment });
        EventBus.$emit('open-toaster', {
          component: Toaster,
          props: { text: 'ההוצאה סומנה כלא תזרימית' },
        });
      }
      this.$emit('close');
    },
  },
};

const actionsByType = actionsMapping => {
  const savingActions = [actionsMapping.moveToSaving];

  return {
    variableIncome: [
      actionsMapping.addComment,
      actionsMapping.editIncomeCategory,
      actionsMapping.splitTransaction,
      ...actionsMapping.shareWithMemberActions,
    ],
    variable: [
      actionsMapping.addComment,
      actionsMapping.editCategory,
      actionsMapping.splitTransaction,
      ...savingActions,
      ...actionsMapping.shareWithMemberActions,
    ],
    trackingCategory: [
      actionsMapping.addComment,
      actionsMapping.editCategory,
      actionsMapping.splitTransaction,
      ...savingActions,
      ...actionsMapping.shareWithMemberActions,
    ],
    excludedIncome: [
      actionsMapping.addComment,
      actionsMapping.moveExcludedBack,
      actionsMapping.splitTransaction,
      ...actionsMapping.shareWithMemberActions,
    ],
    excludedExpense: [
      actionsMapping.addComment,
      actionsMapping.moveExcludedBack,
      actionsMapping.splitTransaction,
      actionsMapping.markOrUnmarkExcludedAsSavings,
      ...actionsMapping.shareWithMemberActions,
    ],
    oneTimeSaving: _.compact([
      actionsMapping.addComment,
      actionsMapping.moveToFixedSaving,
      actionsMapping.splitTransaction,
      actionsMapping.notOneTimeSaving,
      ...actionsMapping.shareWithMemberActions,
    ]),
  };
};

function getActionsOfType(actionsMapping, isExcludedCategory, isIncome, categoryName, isSaving) {
  const actionsMap = actionsByType(actionsMapping);
  if (isExcludedCategory && isIncome) {
    return actionsMap.excludedIncome;
  }
  if (isExcludedCategory && !isIncome) {
    return actionsMap.excludedExpense;
  }
  if (isIncome) {
    return actionsMap.variableIncome;
  }

  if (categoryName === cashflowViewConsts.CATEGORY_NAMES.TRACKING_CATEGORY) {
    return actionsMap.trackingCategory;
  }

  if (isSaving) {
    return actionsMap.oneTimeSaving;
  }

  return actionsMap.variable;
}

function savingActions(actionsMapping) {
  return [actionsMapping.moveToSaving, actionsMapping.moveToFixedSaving];
}

function getActions(isExcludedCategory, isSaving, categoryName, isIncome, actionsMapping, isSplit) {
  const actions = getActionsOfType(actionsMapping, isExcludedCategory, isIncome, categoryName, isSaving);
  if (isSplit) {
    return _.reject(actions, action => savingActions(actionsMapping)
      .includes(action));
  }
  return actions;
}

</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.transaction-container {
  transition: background-color 0.3s;
  background-color: $riseup_gray_0;
  border-bottom: 1px solid $riseup_gray_0;
  &:first-of-type {
    box-shadow: inset 0 2px 3px 0 rgba(155, 155, 155, 0.51);
  }
  &:last-of-type {
    border-bottom: none;
  }
}

</style>
