<template>
  <div class="on-transaction-label"
       :class="variant"
       @click.stop="onClick">
    <slot name="label-text"></slot>
    <div class="what-to-do" :class="{ link: isCallToActionLink }">{{ callToAction }}</div>
  </div>
</template>

<script>
export default {
  name: 'OnTransactionLabel',
  props: {
    isCallToActionLink: { type: Boolean, default: false },
    onClick: { required: true },
    callToAction: { required: false, default: 'מה כדאי לעשות?' },
    variant: {
      type: String,
      validator: val => [
        'warning', 'info',
      ].includes(val),
      default: 'info',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.on-transaction-label {
  padding: 8px 14px 8px 12px;
  line-height: 18px;
  &.warning {
    background-color: $riseup_danger_light_red;
  }
  &.info {
    background-color: $riseup_light_blue;
  }
}

.link {
  color: $riseup_blue;
  cursor: pointer;
}

.what-to-do {
  font-weight: bold;
  text-decoration: underline
}
</style>
