<template>
  <on-transaction-label :on-click="this.openFacebookGroup" is-call-to-action-link call-to-action="בואו לראות איך">
    <template v-slot:label-text>
      <div>התשלום הזה מוכר לך? בקהילה שלנו לקוחות גילו חיובים שלא הכירו מ{{ subscriptionName }} והצליחו לבטל אותם.</div>
    </template>
  </on-transaction-label>
</template>
<script>

import Segment from '@/Segment';
import OnTransactionLabel
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/OnTransactionLabel.vue';
import EnterViewport from '@/mixins/EnterViewport.vue';

export default {
  name: 'might-be-pango-simple',
  components: { OnTransactionLabel },
  mixins: [EnterViewport],
  props: {
    subscriptionName: { required: true },
    conversationUrl: { required: true },
    id: { required: true },
  },
  created() {
    Segment.trackUserInteraction('MightBePangoSimpleLabelCreated', { name: this.id });
  },
  methods: {
    openFacebookGroup() {
      Segment.trackUserInteraction('MightBePangoSimpleLabelClicked', { name: this.id });
      window.open(this.conversationUrl, '_blank');
    },
    onEnterViewport() {
      Segment.trackUserInteraction('MightBePangoSimpleLabelViewed', { name: this.id });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
