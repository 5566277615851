<template>
  <transaction-row-base :actualAmount="actualAmount"
                        :transactionDate="transactionDate"
                        :transactionDescription="transactionDescription"
                        :isSelected="isSelected"
                        :out-of-date="outOfDate"
                        :is-editable="isEditable"
                        :has-update="hasUpdate"
                        :is-temp="transaction.isTemp"
                        :customer-comment="customerComment"
                        :payment-app-details="paymentAppDetails"
                        :splitFromTransaction="splitFromTransaction"
                        :is-postponed="isPostponed"
                        :source="source"
                        :transaction="transaction">
    <template v-slot:on-transaction-label>
      <papa-investigation-label v-if="papaInvestigation" :papa-investigation="papaInvestigation"/>
      <high-income-exclusion-label v-else-if="isExcludedHighIncome" :transaction="transaction"/>
      <might-be-pango-simple v-else-if="mightBePangoSimple" subscription-name="פנגו" id="pango"
                             conversation-url="https://www.facebook.com/groups/riseuptogethergroup/posts/3048325342139492"/>
      <might-be-pango-simple v-else-if="mightBeCelloparkSubscription" subscription-name="סלופארק" id="cellopark"
                             conversation-url="https://www.facebook.com/groups/riseuptogethergroup/posts/3065617060410320/"/>
    </template>
  </transaction-row-base>
</template>

<script>
import * as _ from 'lodash';
import MightBePangoSimple
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/MightBePangoSimpleLabel.vue';
import HighIncomeExclusionLabel
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/HighIncomeExclusionLabel.vue';
import PapaInvestigationLabel
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/PapaInvestigationLabel.vue';
import TransactionDisplayUtils from '../../TransactionDisplayUtils.vue';
import TransactionRowBase from './TransactionRowBase';

const PANGO_BUSINESS_NAME = 'פנגו חשבונית חודשית';
const POSSIBLE_PANGO_SIMPLE_AMOUNTS = [
  9.9, 9.9 * 2, 9.9 * 3, 9.9 * 4, 7.9, 7.9 * 2, 4.9,
];
const CELLOPARK_BUSINESS_NAME = 'סלופארק';
const POSSIBLE_CELLOPARK_AMOUNTS = [
  7.9, 7.9 * 2, 7.9 * 3, 9.9, 9.9 * 2, 9.9 * 3, 15.9, 15.9 + 5,
];
export default {
  name: 'TransactionRow',
  mixins: [TransactionDisplayUtils],
  components: {
    MightBePangoSimple,
    HighIncomeExclusionLabel,
    PapaInvestigationLabel,
    TransactionRowBase,
  },
  props: [
    'papaInvestigation',
    'transaction',
    'isEditable',
    'categoryId',
    'isSelected',
    'outOfDate',
    'splitFromTransaction',
    'hasUpdate',
  ],
  computed: {
    isTransactionEditable() {
      return this.canEditCategory;
    },
    transactionDate() {
      return this.transaction.transactionDate;
    },
    split() {
      return !_.isEmpty(this.transaction.splitFrom);
    },
    customerComment() {
      return this.transaction.customerComment;
    },
    paymentAppDetails() {
      return this.getPaymentAppDetails(this.transaction);
    },
    isPostponed() {
      return this.transaction.isPostponed;
    },
    actualAmount() {
      return +this.getTransactionAmountWithoutFraction(this.transaction);
    },
    businessName() {
      return this.transaction.businessName;
    },
    transactionPayment() {
      return this.getTransactionPayment(this.transaction);
    },
    transactionAccount() {
      if (!this.transaction.accountNumberPiiValue) {
        return '';
      }
      return this.getTransactionAccount(this.transaction);
    },
    transactionDescription() {
      if (!this.businessName && !this.transactionAccount) {
        return '';
      }
      return `${this.businessName} ${this.transactionPayment} ${this.transactionAccount}`;
    },
    source() {
      return this.transaction.source;
    },
    isExcludedHighIncome() {
      return this.transaction.excludeReason === 'highIncomeExclusion';
    },
    mightBePangoSimple() {
      return this.transaction.businessName === PANGO_BUSINESS_NAME && POSSIBLE_PANGO_SIMPLE_AMOUNTS.includes(this.transaction.billingAmount);
    },
    mightBeCelloparkSubscription() {
      return this.transaction.businessName?.includes(CELLOPARK_BUSINESS_NAME) && POSSIBLE_CELLOPARK_AMOUNTS.includes(this.transaction.billingAmount);
    },
  },
  methods: {
    getTransactionPayment(transaction) {
      return transaction.paymentNumber > 0 ? `- תשלום ${transaction.paymentNumber}/${transaction.totalNumberOfPayments}` : '';
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
</style>
